export enum RecallaiMeetingType {
    ZOOM = 'zoom',
    MEET = 'meet',
    TEAMS = 'teams',
    SLACK = 'slack',
}

export interface RecallaiMeeting {
    meeting_type: RecallaiMeetingType
    meeting_url: string
    meeting_status: string
    meeting_title?: string
    recallai_bot_id?: string
    campaign_id?: string
    author_id: string
    interview_id?: string
}
