import { InterviewApi } from '../api/interviews'
import ConfirmationModal from '../components/ConfirmationModal'
import InterviewLandingPageSoundbites from '../components/InterviewLandingPageSoundbites'
import InterviewLandingPageTopics from '../components/InterviewLandingPageTopics'
import InterviewResponseUploadModal from '../components/InterviewResponseUploadModal'
import MeetingLinkModal from '../components/MeetingLinkModal'
import QrCodeModalInterview from '../components/QrCodeModalInterview'
import Spinner from '../components/Spinner'
import { useRagStore } from '../context/rag/store'
import { Page } from '../enums/Page'
import { EmotionsByThemeResponse } from '../types/EmotionsByThemeResponse'
import { Interview } from '../types/Interview'
import { ListOfInterviewResponses } from '../types/ListOfInterviewResponses'
import { saveAs } from 'file-saver'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ToneOfVoiceProps {
    emotionsData: EmotionsByThemeResponse[]
    selectedTopic: string | null
    selectedTone: string | undefined
    onToneChange: (tone: string | undefined) => void
}

const ToneOfVoice = ({
    emotionsData,
    selectedTopic,
    selectedTone,
    onToneChange,
}: ToneOfVoiceProps): JSX.Element => {
    const topEmotions = emotionsData?.map((data) => data.top_emotions).flat()

    useEffect(() => {
        onToneChange(undefined)
    }, [selectedTopic])

    useEffect(() => {
        if (selectedTopic === undefined) {
            onToneChange('') // Reset to an empty string instead of undefined
        }
    }, [selectedTopic, onToneChange]) // Add onToneChange to the dependency array to follow best practices

    const uniqueEmotions = Array.from(new Set(topEmotions))

    return (
        <div className="flex items-center">
            <p className="uppercase text-[#007AFF] text-sm sm:text-base font-lato font-bold pr-4">
                Tone of voice
            </p>
            <div>
                <select
                    className="rounded-2xl px-8 sm:min-w-[300px]"
                    value={selectedTone || ''} // Fallback to empty string if selectedTone is undefined
                    onChange={(e) => {
                        onToneChange(e.target.value || undefined) // Convert empty string back to undefined when resetting
                    }}
                >
                    <option value="">
                        {uniqueEmotions.length > 0 ? 'Select one' : 'Waiting for voices...'}
                    </option>
                    {uniqueEmotions.map((emotion: string) => {
                        return (
                            <option key={emotion} value={emotion}>
                                {emotion}
                            </option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}

// const ContentIdeas = (): JSX.Element => {
//     return (
//         <div className="flex items-center">
//             <p className="uppercase text-black text-sm sm:text-base font-lato font-bold pr-4 border-r-2 border-[#b6d6fa]">
//                 Content ideas
//             </p>
//             <p className="text-black text-sm sm:text-lg font-lato pl-4">
//                 Editable text for social posts, blog ideas, and white papers{' '}
//             </p>
//             <p className="text-[#5C8EC6] italic text-sm sm:text-lg font-lato ml-8">coming soon</p>
//         </div>
//     )
// }

const AssistantInfoText = (): JSX.Element => {
    return (
        <div className="flex items-center bg-[#f9f9f9] rounded-[10px] p-3">
            <p className="font-lato pr-1">💡</p>
            <p className="text-black text-sm font-lato pl-1 font-light">
                Ask the Assistant to analyze and write in tones found across these conversations. In
                the next version, it will be easier to visualize differences in tone across topics.{' '}
                <span className="font-black">
                    <b>What else would you like to see here?</b>
                </span>{' '}
                <a
                    href="https://toneai.app/ria94075/what-should-we-know-about-your-190228"
                    target="_blank"
                    className="text-sm text-[#007AFF] dark:text-blue-500 hover:underline text-right"
                >
                    Let us know
                </a>
            </p>
        </div>
    )
}

const InterviewLandingPage = (): JSX.Element => {
    const { state } = useLocation()
    const [interview, setInterview] = useState<Interview | null>(null)
    const [interviewResponsesData, setInterviewResponsesData] =
        useState<ListOfInterviewResponses | null>(null)
    const [emotionsData, setEmotionsData] = useState<EmotionsByThemeResponse[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)
    const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false)
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
    const { username, slug } = useParams<{ username: string; slug: string }>()
    const [selectedTopic, setSelectedTopic] = useState<string | null>(null)
    const [showDeleteLiveConversationModal, setShowDeleteLiveConversationModal] =
        useState<boolean>(false)
    const [editingTitle, setEditingTitle] = useState<boolean>(false)
    const [editedTitle, setEditedTitle] = useState<string>('')
    const [showAll, setShowAll] = useState<boolean>(false)
    const [selectedToneOfVoice, setSelectedToneOfVoice] = useState<string | undefined>(undefined)
    const [noInterviewResponses, setNoInterviewResponses] = useState<boolean>(false)
    const [showMeetingLinkModal, setShowMeetingLinkModal] = useState<boolean>(false)
    const campaignId = state?.campaignId
    const userId = state?.userId

    const navigate = useNavigate()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { setInterviewIds, setSurveyIds, setRagSourcePage } = useRagStore()

    const fetchResponses = async (id: string): Promise<void> => {
        const response = await InterviewApi.getInterviewResponses(id)
        setInterviewResponsesData(response.data)
        if (response.data.interview_responses.length === 0) setNoInterviewResponses(true)
        else setNoInterviewResponses(false)
    }

    const fetchEmotionAnalysis = async (id: string): Promise<void> => {
        if (selectedTopic) {
            try {
                setLoading(true)
                const response = await InterviewApi.getEmotionAnalysisByInterviewIdAndThemeName(
                    id,
                    selectedTopic,
                )
                setEmotionsData(response.data)
            } finally {
                setLoading(false)
            }
        }
    }

    const onEditTitleEnabled = () => {
        setEditingTitle(true)
        setEditedTitle(interview?.title ?? '')
        if (inputRef && inputRef.current) {
            inputRef.current.focus()
        }
    }

    const fetchInterview = async () => {
        try {
            setLoading(true)
            if (slug) {
                const response = await InterviewApi.getBySlug(slug)
                setInterview(response.data)
                if (response.data?.top_topics && response.data.top_topics?.length > 0) {
                    setSelectedTopic(response.data.top_topics[0])
                }
                await fetchResponses(response.data.id)
                setSurveyIds([])
                setInterviewIds([response.data.id])
                setRagSourcePage('CONVERSATIONS_LANDING_PAGE')
            }
        } catch (err) {
            setInterview(null)
        } finally {
            setLoading(false)
        }
    }

    const downloadReportButtonClicked = async () => {
        try {
            setLoading(true)
            const res = await InterviewApi.downloadReport(interview?.id as string)
            if (res.status === 200) {
                var blob = new Blob([res.data])
                saveAs(blob, `${slug}.xlsx`)
            }
        } finally {
            setLoading(false)
        }
    }

    const updateInterviewTitle = async () => {
        if (interview?.id) {
            await InterviewApi.updateInterviewById(interview.id, editedTitle)
            await fetchInterview()
        }
        setEditingTitle(false)
    }

    const deleteLiveConversationHandler = async () => {
        if (interview) {
            await InterviewApi.deleteById(interview.id)
            setShowDeleteLiveConversationModal(false)
            if (campaignId && username) {
                navigate(
                    generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                        username: username,
                        id: campaignId,
                    }),
                )
            } else {
                navigate(Page.HOME)
            }
        }
    }

    useEffect(() => {
        if (slug) {
            void fetchInterview()
        }
    }, [slug])

    useEffect(() => {
        if (selectedTopic && interview?.id) {
            void fetchEmotionAnalysis(interview.id)
        }
    }, [selectedTopic])

    const showToast = () => {
        toast.success(
            'Your file has been uploaded successfully. It is currently being processed, which may take a few minutes. Please refresh the page in a few minutes to see your file.',
            {
                position: 'bottom-center',
                autoClose: 5000, // closes after 5000ms
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                closeButton: true,
            },
        )
    }

    return (
        <div className="flex-1 flex flex-col gap-8 sm:gap-12 px-[20px] sm:px-[40px] lg:pr-[120px] pb-6 sm:pb-10">
            <>{loading && <Spinner />}</>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="flex flex-row items-center w-full sm:w-3/5">
                    <button
                        className="text-[#007AFF] font-lato font-bold text-base pr-3"
                        onClick={() => {
                            if (campaignId && username) {
                                navigate(
                                    generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                                        username: username,
                                        id: campaignId,
                                    }),
                                )
                            } else {
                                navigate(Page.HOME)
                            }
                        }}
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.93934 13.9393C8.35355 14.5251 8.35355 15.4749 8.93934 16.0607L18.4853 25.6066C19.0711 26.1924 20.0208 26.1924 20.6066 25.6066C21.1924 25.0208 21.1924 24.0711 20.6066 23.4853L12.1213 15L20.6066 6.51472C21.1924 5.92893 21.1924 4.97919 20.6066 4.3934C20.0208 3.80761 19.0711 3.80761 18.4853 4.3934L8.93934 13.9393ZM12 13.5H10V16.5H12V13.5Z"
                                fill="#8092A5"
                            />
                        </svg>
                    </button>
                    {editingTitle ? (
                        <input
                            ref={inputRef}
                            className="w-full text-[16px] sm:text-[25px] font-bold text-[#8092A5] outline-none focus-within:outline-none bg-transparent"
                            value={editedTitle}
                            onChange={(e) => setEditedTitle(e.target.value)}
                            onFocus={(e) =>
                                e.currentTarget.setSelectionRange(
                                    e.currentTarget.value.length,
                                    e.currentTarget.value.length,
                                )
                            }
                        />
                    ) : (
                        <h2 className="w-full sm:w-full text-[16px] sm:text-[25px] font-bold text-[#8092A5]">
                            {interview?.title}
                        </h2>
                    )}
                </div>
                {editingTitle ? (
                    <div className="flex flex-row items-end sm:items-center gap-2 sm:gap-4">
                        <button
                            className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                            onClick={() => setEditingTitle(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                            onClick={() => updateInterviewTitle()}
                        >
                            Save
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-row items-center justify-end gap-1 lg:gap-4 w-full">
                        <button
                            onClick={downloadReportButtonClicked}
                            title="Download report"
                            className="p-1 sm:p-2"
                        >
                            <img src="/asset/download-icon.svg" alt="download_button" />
                        </button>
                        <button
                            className="p-1 sm:p-2"
                            onClick={() => setShowDeleteLiveConversationModal(true)}
                        >
                            <img
                                src="/asset/trash_bin.svg"
                                width={24}
                                height={24}
                                alt="trash_bin"
                            />
                        </button>
                        <button className="p-1 sm:p-2" onClick={onEditTitleEnabled}>
                            <img src="/asset/pencil.svg" width={24} height={24} alt="pencil" />
                        </button>
                        <button className="p-1 sm:p-2" onClick={() => setShowQrCodeModal(true)}>
                            <img src="/asset/qr_code.svg" alt="qr_code" width={24} height={24} />
                        </button>
                        {!isLinkCopied && (
                            <button
                                className="p-1 sm:p-2"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${window.location.origin}/${username}/interview/${slug}`,
                                    )
                                    setIsLinkCopied(true)
                                    setTimeout(() => {
                                        setIsLinkCopied(false)
                                    }, 3000)
                                }}
                            >
                                <img src="/asset/link.svg" alt="link" width={28} height={24} />
                            </button>
                        )}
                        {isLinkCopied && (
                            <p className="text-sm sm:text-base leading-[30px] duration-300 transition-colors text-[#007AFF]">
                                Copied!
                            </p>
                        )}
                        {/*<button className={`text-[#BFDEFF] bg-[#007AFF] px-6 py-1 rounded-2xl`}>*/}
                        {/*    Upload*/}
                        {/*</button>*/}
                        <button
                            onClick={() => setShowUploadModal(true)}
                            className={`text-sm sm:text-base text-[#BFDEFF] bg-[#007AFF] px-2 sm:px-6 py-1 rounded-2xl`}
                        >
                            Upload
                        </button>
                        <button
                            className={`text-sm sm:text-base text-[#BFDEFF] bg-[#007AFF] px-2 sm:px-6 py-1 rounded-2xl`}
                            onClick={() => setShowMeetingLinkModal(true)}
                        >
                            Add To Meeting
                        </button>
                    </div>
                )}
            </div>
            {interview?.top_topics && (
                <InterviewLandingPageTopics
                    topics={interview?.top_topics ?? []}
                    selectedTopic={selectedTopic}
                    onSelectTopic={(topic) => {
                        setSelectedToneOfVoice(undefined)
                        setSelectedTopic(topic)
                    }}
                    noInterviewResponses={noInterviewResponses}
                />
            )}
            <ToneOfVoice
                emotionsData={emotionsData}
                selectedTopic={selectedTopic}
                selectedTone={selectedToneOfVoice}
                onToneChange={setSelectedToneOfVoice}
            />
            <AssistantInfoText />
            {/* <ContentIdeas /> */}
            <InterviewLandingPageSoundbites
                interviewResponsesData={interviewResponsesData}
                emotionsData={emotionsData}
                selectedTopic={selectedTopic}
                selectedTone={selectedToneOfVoice}
                noInterviewResponses={noInterviewResponses}
                onS3KeyExpired={async () => {
                    await fetchResponses(interview?.id ?? '')
                }}
            />
            <div className="bg-[#f9f9f9] px-4 sm:px-12 py-8 rounded-[30px]">
                <div className="flex flex-row items-center justify-between mb-4">
                    <div className="flex flex-row items-center">
                        <p className="text-[#007AFF] text-sm sm:text-base font-bold font-lato pr-2">
                            CONVERSATIONS
                        </p>
                        <span className="text-[#007AFF] text-sm mr-4 px-2 py-1 border-[1.5px] border-[#007AFF] rounded-2xl text-center flex items-center justify-center">
                            {interviewResponsesData?.interview_responses.length}
                        </span>
                        <p className="text-[#9E9E9E] text-xs sm:text-base font-lato pl-4 border-l-2 border-[#b6d6fa]">
                            {noInterviewResponses
                                ? 'Record your first conversation :)'
                                : 'Click on a conversation to view transcript'}
                        </p>
                    </div>
                </div>
                <div>
                    {interviewResponsesData?.interview_responses
                        .slice(0, showAll ? interviewResponsesData?.interview_responses.length : 3)
                        .map((response, index) => {
                            return (
                                <div
                                    className="flex flex-row justify-between bg-white mb-4 rounded-2xl cursor-pointer"
                                    key={index}
                                    onClick={() => {
                                        navigate(
                                            generatePath(Page.INTERVIEW_RESPONSE_LANDING_PAGE, {
                                                id: response.id,
                                            }),
                                            {
                                                state: {
                                                    campaignId: campaignId,
                                                },
                                            },
                                        )
                                    }}
                                >
                                    <div className="px-4 sm:px-12 py-4 flex flex-row items-center">
                                        <p className="pr-4 border-r-2 border-[#b6d6fa] w-fit text-[#001f40] text-xs sm:text-base font-lato">
                                            {moment(new Date()).diff(response.created_at, 'hours') >
                                            72
                                                ? moment(response.created_at).format('LL')
                                                : moment(response.created_at).fromNow()}
                                        </p>
                                        <p className="px-4 w-fit text-[#001f40] text-xs sm:text-base font-lato pr-4 border-r-2 border-[#b6d6fa]">
                                            {response?.title?.length
                                                ? response.title
                                                : `Re: ${interview?.title}`}
                                        </p>
                                        {response.responders?.length > 0 && (
                                            <p className="pl-4 w-fit text-[#001f40] text-xs sm:text-base font-lato pr-4">
                                                {response.responders.map((responder, index) => {
                                                    return (
                                                        <span key={index}>
                                                            {`${responder.first_name ?? ''} ${
                                                                responder.last_name ?? ''
                                                            }`}
                                                            {index < response.responders.length - 1
                                                                ? ', '
                                                                : ''}
                                                        </span>
                                                    )
                                                })}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex flex-row items-center pr-4">
                                        <img
                                            src="/asset/right-blue-double-arrow-icon.svg"
                                            alt="right-blue-double-arrow-icon"
                                        />
                                    </div>
                                </div>
                            )
                        })}
                </div>
                <div className="flex flex-row justify-end items-center mt-8 px-2">
                    {interviewResponsesData &&
                        interviewResponsesData?.pagination.total_items > 3 && (
                            <button
                                className="text-[#007AFF] text-base font-bold font-lato"
                                onClick={() => setShowAll(!showAll)}
                            >
                                {showAll ? 'View less' : 'View all'}
                            </button>
                        )}
                </div>
            </div>
            <div>
                {showQrCodeModal && interview && (
                    <QrCodeModalInterview
                        onClose={() => setShowQrCodeModal(false)}
                        title={interview.title}
                        link={encodeURI(`${window.location.origin}/${username}/interview/${slug}`)}
                    />
                )}
            </div>
            <ConfirmationModal
                question="Are you sure you want to delete this live conversation? This action cannot be undone."
                show={!!showDeleteLiveConversationModal}
                onClose={() => setShowDeleteLiveConversationModal(false)}
                onConfirm={deleteLiveConversationHandler}
            />
            {showUploadModal && (
                <InterviewResponseUploadModal
                    show={true}
                    interview={interview}
                    onClose={() => setShowUploadModal(false)}
                    onFileUpload={() => {
                        showToast()
                        setShowUploadModal(false)
                    }}
                />
            )}
            <div>
                {showMeetingLinkModal && (
                    <MeetingLinkModal
                        onClose={() => setShowMeetingLinkModal(false)}
                        authorId={userId || ''}
                        campaignId={campaignId || ''}
                        interviewId={interview?.id || ''}
                    />
                )}
            </div>
        </div>
    )
}
export default InterviewLandingPage
