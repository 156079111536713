import { useEffect } from 'react'

const ZoomSupportDocs = (): JSX.Element => {
    useEffect(() => {
        document.body.style.overflowY = 'scroll'
    })

    return (
        <div className="container mx-auto h-screen pt-[100px]">
            <div className="flex flex-row justify-center">
                <img src="/asset/toneai-logo.png" />
            </div>
            <h1 className="font-lato font-bold underline mt-10 mb-[60px] text-center text-[26px]">
                Zoom App Installation, Usage, and Uninstallation Guide for Tone AI Meeting Bots
            </h1>
            <h1>
                <strong>1. Add Tone AI Zoom Meeting Bot to the Zoom meeting</strong>
            </h1>
            <br />
            <p className="ml-5">
                <strong>Step 1:</strong> Login to Tone AI platform
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>
                    Open your browser and go to the{' '}
                    <a href="https://toneai.app/" target="_blank">
                        Tone AI web app
                    </a>
                    .{' '}
                </li>
                <li>
                    Log in to your Tone AI account. Please reach out to us at{' '}
                    <a href="mailto:hello@toneai.xyz">hello@toneai.xyz</a> if you do not have an
                    account.{' '}
                </li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 2:</strong> Select a campaign to store the meeting data
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>Select an existing campaign for the meeting data.</li>
                <li>
                    If you don't have a campaign or would like a new one, click on the "Create
                    Campaign" button.
                </li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 3:</strong> Specify the folder to store the meeting data
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>
                    Once campaign is selected, select an existing folder to add the meeting bot to.
                </li>
                <li>
                    If folder doesn't exist or want a new folder, click the "Add New" button to
                    create a new folder.
                </li>
                <li>
                    Once folder is selected, click the "Add To Meeting" button to add meeting bot to
                    your Zoom meeting.
                </li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 4:</strong> Configure Tone AI Meeting Assistant for Zoom meeting
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>In the Add To Meeting dialog window, select "Zoom" as your meeting type.</li>
                <li>Specify a meeting title which (optional).</li>
                <li>Specify the Zoom meeting url.</li>
                <li>Click the "Add To Meeting" button to setup the Zoom meeting assistant.</li>
            </ul>
            <br />
            <h1>
                <strong>2. Using the Tone AI Meeting Assistant Zoom App</strong>
            </h1>
            <br />
            <p className="ml-5">
                <strong>Step 1:</strong> Start or Join a Zoom Meeting
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>
                    Launch Zoom and start or join a meeting where you added the Tone AI bot to
                    participate.
                </li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 2:</strong> Activate the Bot
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>Allow the Tone AI Meeting assistant to join from within Zoom.</li>
                <li>Follow the prompts to add the bot into your meeting.</li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 3:</strong> Use Meeting Bot Features
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>
                    <strong>Transcription:</strong> The bot will automatically start transcribing
                    the meeting.
                </li>
                <li>
                    <strong>Recording:</strong> If enabled, the bot will record the meeting.
                </li>
                <li>
                    <strong>Analytics:</strong> After the meeting, you can view summaries, keyword
                    highlights, and action points in the Tone AI web app.
                </li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 4:</strong> End the Session
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>
                    The bot will leave the meeting when the session ends or when you remove it
                    manually.
                </li>
                <li>Access meeting outputs on the Tone AI platform.</li>
            </ul>
            <br />
            <h1>
                <strong>3. Uninstalling the Zoom App</strong>
            </h1>
            <br />
            <p className="ml-5">
                <strong>Step 1:</strong> Open the Zoom App Marketplace
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>Log in to your Zoom account.</li>
                <li>
                    Navigate to the{' '}
                    <a href="https://marketplace.zoom.us/" target="_blank">
                        Zoom App Marketplace
                    </a>
                    .
                </li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 2:</strong> Manage Installed Apps
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>
                    Click on your profile icon in the top-right corner and select{' '}
                    <strong>Manage</strong>.
                </li>
                <li>
                    Under the <strong>Installed Apps</strong> tab, locate{' '}
                    <strong>Tone AI Meeting Assistant</strong>.
                </li>
            </ul>
            <br />
            <p className="ml-5">
                <strong>Step 3:</strong> Uninstall the App
            </p>
            <br />
            <ul className="ml-10 list-disc">
                <li>
                    To cancel your account, reach out to{' '}
                    <a href="mailto:hello@toneai.xyz">Tone AI Support</a> and request your account
                    to be canceled.
                </li>
                <li>
                    This will remove your account and Tone AI Zoom meeting assistant will be
                    disabled.
                </li>
            </ul>
            <br />
            <div>
                <p>
                    For troubleshooting or additional assistance, contact{' '}
                    <strong>Tone AI Support:</strong>
                </p>
                <br />
                <ul>
                    <li>
                        Email: <a href="mailto:hello@toneai.xyz">hello@toneai.xyz</a>
                    </li>
                    <li>
                        Website:{' '}
                        <a href="https://toneai.xyz/" target="_blank">
                            https://toneai.xyz/
                        </a>
                    </li>
                </ul>
            </div>
            <br />
            <br />
        </div>
    )
}
export default ZoomSupportDocs
